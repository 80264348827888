define("accounting/format", ["exports", "accounting/format-number"], function (_exports, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Alias of formatNumber
   *
   * @method format
   * @for accounting
   */
  var _default = _formatNumber.default;
  _exports.default = _default;
});