define("semantic-ui-ember/components/ui-dimmer", ["exports", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-dimmer"], function (_exports, _base, _uiDimmer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_base.default, {
    layout: _uiDimmer.default,
    module: 'dimmer'
  });
  _exports.default = _default;
});