define("ember-l10n/utils/guess-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getLocalAlias = getLocalAlias;
  _exports.guessLocale = guessLocale;
  _exports.matchLocale = matchLocale;
  _exports.normalizeLocale = normalizeLocale;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Guess a locale based on allowed & desired locales.
   * This will return the best-fitting locale.
   *
   * Given the following input:
   * allowedLocales = ['en', 'de', 'zh_HK']
   * desiredLocales = ['de-AT', 'de', 'en-US', 'en']
   *
   * It would by default return 'de'.
   *
   * If you specify `allowSubLocales=true`, it would instead return `de_AT`, the favorite sub-locale.
   *
   * In contrast, the following input:
   * allowedLocales = ['en', 'de', 'zh_HK']
   * desiredLocales = ['zh-CN', 'zh-HK', 'en-US', 'en']
   *
   * Would always return 'zh_HK', no matter if sub locales are allowed or not.
   *
   * @method guessLocale
   * @param allowedLocales
   * @param desiredLocales
   * @param defaultLocale
   * @param allowSubLocales
   * @return {String}
   */
  function guessLocale() {
    var allowedLocales = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var desiredLocales = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$defaultLocale = _ref.defaultLocale,
      defaultLocale = _ref$defaultLocale === void 0 ? 'en' : _ref$defaultLocale,
      _ref$allowSubLocales = _ref.allowSubLocales,
      allowSubLocales = _ref$allowSubLocales === void 0 ? false : _ref$allowSubLocales;
    desiredLocales = desiredLocales || [defaultLocale];
    desiredLocales = desiredLocales.map(normalizeLocale).map(getLocalAlias);

    // Ensure everything is an Ember Array
    if (!desiredLocales.find) {
      desiredLocales = Ember.A(desiredLocales);
    }
    if (!allowedLocales.find) {
      allowedLocales = Ember.A(allowedLocales);
    }
    var locale = desiredLocales.find(function (locale) {
      return allowedLocales.find(function (allowedLocale) {
        return matchLocale(locale, allowedLocale);
      });
    }) || defaultLocale;

    // If allowSubLocales=false, we do not want to return sub locales
    // For example, if 'de' is allowed, but the first matching locale is de_AT, it will return 'de' if true, else de_AT.
    if (allowSubLocales || allowedLocales.indexOf(locale) !== -1) {
      return locale;
    }
    return allowedLocales.find(function (allowedLocale) {
      return locale.indexOf(allowedLocale) === 0;
    }) || defaultLocale;
  }
  function normalizeLocale(locale) {
    locale = locale.replace('-', '_');
    var _locale$split = locale.split('_'),
      _locale$split2 = _slicedToArray(_locale$split, 2),
      mainLocale = _locale$split2[0],
      region = _locale$split2[1];
    if (region) {
      return "".concat(mainLocale, "_").concat(region.toUpperCase());
    }
    return mainLocale;
  }
  function getLocalAlias(locale) {
    // There are variations of chinese locales
    // We need to map those to either Simplified (CN) or Traditional (HK).
    // Sadly, we cannot simply fall back to zh here, as that is not actually a valid locale
    switch (locale) {
      case 'zh_CN':
      case 'zh_SG':
      case 'zh_Hans':
      case 'zh':
        return 'zh_CN';
      case 'zh_HK':
      case 'zh_TW':
      case 'zh_MO':
      case 'zh_Hant':
        return 'zh_HK';
    }
    return locale;
  }
  function matchLocale(localeA, localeB) {
    if (localeA === localeB) {
      return true;
    }
    return localeA.indexOf(localeB) === 0;
  }
  var _default = guessLocale;
  _exports.default = _default;
});