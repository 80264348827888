define("semantic-ui-ember/templates/components/ui-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8irEG0zE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[[32,0],\"execute\"],null]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "semantic-ui-ember/templates/components/ui-search.hbs"
    }
  });
  _exports.default = _default;
});