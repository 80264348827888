define("ember-data-storefront/mixins/loadable-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
    This mixin adds new data-loading methods to your Ember Data models.
  
    To use it, extend a model and mix it in:
  
    ```js
    // app/models/post.js
    import DS from 'ember-data';
    import LoadableModel from 'ember-data-storefront/mixins/loadable-model';
  
    export default DS.Model.extend(LoadableModel);
    ```
  
    Once you understand how `LoadableModel` works We suggest adding it to every model in your app. You can do this by reopening `DS.Model` in `app.js.` and mixing it in:
  
    ```js
    // app/app.js
    import DS from 'ember-data';
    import LoadableModel from 'ember-data-storefront/mixins/loadable-model';
  
    DS.Model.reopen(LoadableModel);
    ```
  
    @class LoadableModel
    @public
  */
  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_loadedReferences', {});
    },
    reloadWith: function reloadWith() {
      (false && !(false) && Ember.deprecate('reloadWith has been renamed to sideload. Please change all instances of reloadWith in your app to sideload. reloadWith will be removed in 1.0', false, {
        id: 'ember-data-storefront.reloadWith',
        until: '1.0.0'
      }));
      return this.sideload.apply(this, arguments);
    },
    /**
     `sideload` gives you an explicit way to asynchronously sideload related data.
      ```js
     post.sideload('comments');
     ```
      The above uses Storefront's `loadRecord` method to query your backend for the post along with its comments.
      You can also use JSON:API's dot notation to load additional related relationships.
      ```js
     post.sideload('comments.author');
     ```
      Every call to `sideload()` will return a promise.
      ```js
     post.sideload('comments').then((post) => console.log('loaded comments!'));
     ```
      If a relationship has never been loaded, the promise will block until the data is loaded. However, if a relationship has already been loaded (even from calls to `loadRecord` elsewhere in your application), the promise will resolve synchronously with the data from Storefront's cache. This means you don't have to worry about overcalling `sideload()`.
      When relationship data has already been loaded, `sideload` will use a background refresh to update the relationship. To prevent sideload from making network requests for data that has already been loaded, use the `{ backgroundReload: false }` option.
      ```js
     post.sideload('comments', { backgroundReload: false });
     ```
      If you would like calls to `sideload` to always return a blocking promise, use the `{ reload: true }` option.
      ```js
     post.sideload('comments', { reload: true })
     ```
      This feature works best when used on relationships that are defined with `{ async: false }` because it allows `load()` to load the data, and `get()` to access the data that has already been loaded.
      This method relies on JSON:API and assumes that your server supports JSON:API includes.
      @method sideload
     @param {String} includesString a JSON:API includes string representing the relationships to check
     @param {Object} options (optional) a hash of options
     @return {Promise} a promise resolving with the record
     @public
    */
    sideload: function sideload() {
      var modelName = this.constructor.modelName;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      var possibleOptions = args[args.length - 1];
      var options;
      if (typeof possibleOptions === 'string') {
        options = {
          include: args.join(',')
        };
      } else {
        options = _objectSpread(_objectSpread({}, possibleOptions), {
          include: args.slice(0, -1).join(',')
        });
      }
      return this.get('store').loadRecord(modelName, this.get('id'), options);
    },
    /**
      `load` gives you an explicit way to asynchronously load related data.
       ```js
      post.load('comments');
      ```
       The above uses Ember data's references API to load a post's comments from your backend.
       Every call to `load()` will return a promise.
       ```js
      post.load('comments').then((comments) => console.log('loaded comments as', comments));
      ```
       If a relationship has never been loaded, the promise will block until the data is loaded. However, if a relationship has already been loaded, the promise will resolve synchronously with the data from the cache. This means you don't have to worry about overcalling `load()`.
       When relationship data has already been loaded, `load` will use a background refresh to update the relationship. To prevent load from making network requests for data that has already been loaded, use the `{ backgroundReload: false }` option.
       ```js
      post.load('comments', { backgroundReload: false });
      ```
       If you would like calls to `load` to always return a blocking promise, use the `{ reload: true }` option.
       ```js
      post.load('comments', { reload: true })
      ```
       @method load
      @param {String} name the name of the relationship to load
      @return {Promise} a promise resolving with the related data
      @public
    */
    load: function load(name) {
      var _this = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        reload: false,
        backgroundReload: true
      };
      (false && !(!Ember.isArray(name) && !name.includes(',') && !name.includes('.')) && Ember.assert("The #load method only works with a single relationship, if you need to load multiple relationships in one request please use the #sideload method [ember-data-storefront]", !Ember.isArray(name) && !name.includes(',') && !name.includes('.')));
      var reference = this._getReference(name);
      var value = reference.value();
      var shouldBlock = !(value || this.hasLoaded(name)) || options.reload;
      var promise;
      if (shouldBlock) {
        var loadMethod = this._getLoadMethod(name, options);
        promise = reference[loadMethod].call(reference);
      } else {
        promise = Ember.RSVP.resolve(value);
        if (options.backgroundReload) {
          reference.reload();
        }
      }
      return promise.then(function (data) {
        // need to track that we loaded this relationship, since relying on the reference's
        // value existing is not enough
        _this._loadedReferences[name] = true;
        return data;
      });
    },
    /**
      Returns
       @method _hasNamedRelationship
      @param {String} name The name of a relationship
      @return {Boolean} True if the current model has a relationship defined for the provided name
      @private
    */
    _hasNamedRelationship: function _hasNamedRelationship(name) {
      return Boolean(Ember.get(this.constructor, "relationshipsByName").get(name));
    },
    /**
      @method _getRelationshipInfo
      @private
    */
    _getRelationshipInfo: function _getRelationshipInfo(name) {
      var relationshipInfo = Ember.get(this.constructor, "relationshipsByName").get(name);
      (false && !(relationshipInfo) && Ember.assert("You tried to load the relationship ".concat(name, " for a ").concat(this.constructor.modelName, ", but that relationship does not exist [ember-data-storefront]"), relationshipInfo));
      return relationshipInfo;
    },
    /**
      @method _getReference
      @private
    */
    _getReference: function _getReference(name) {
      var relationshipInfo = this._getRelationshipInfo(name);
      var referenceMethod = relationshipInfo.kind;
      return this[referenceMethod](name);
    },
    /**
      Given a relationship name this method will return the best way to load
      that relationship.
       @method _getLoadMethod
      @private
    */
    _getLoadMethod: function _getLoadMethod(name, options) {
      var relationshipInfo = this._getRelationshipInfo(name);
      var reference = this._getReference(name);
      var hasLoaded = this._hasLoadedReference(name);
      var forceReload = options.reload;
      var isAsync;
      if (relationshipInfo.kind === 'hasMany') {
        isAsync = reference.hasManyRelationship.isAsync;
      } else if (relationshipInfo.kind === 'belongsTo') {
        isAsync = reference.belongsToRelationship.isAsync;
      }
      return !forceReload && isAsync && !hasLoaded ? 'load' : 'reload';
    },
    /**
      A list of models for a given relationship. It's always normalized to a list,
      even for belongsTo, null, or unloaded relationships.
       @method _getRelationshipModels
      @private
    */
    _getRelationshipModels: function _getRelationshipModels(name) {
      var reference = this._getReference(name);
      var info = this._getRelationshipInfo(name);
      var models;
      if (info.kind === 'hasMany') {
        models = reference.value() || [];
      } else if (info.kind === 'belongsTo') {
        models = reference.value() ? [reference.value()] : [];
      }
      return models;
    },
    /**
      This is a private method because we may refactor it in the future to have
      a difference signature. However, this method is used by other
      storefront objects. So, it's really public, but don't use it in app code!
       @method trackLoadedIncludes
      @param {String} includes A full include path. Example: "author,comments.author,tags"
      @private
    */
    trackLoadedIncludes: function trackLoadedIncludes(includes) {
      var _this2 = this;
      includes.split(",").forEach(function (path) {
        return _this2._trackLoadedIncludePath(path);
      });
    },
    /**
      Tracks a single include path as being loaded.
       We verify that the current model actually has a named relationship defined
      for the first segment of the include path, because requests for polymorphic
      collections could return mixed sets of models that don't share all of
      the relationships that were requested via includes.
       @method _trackLoadedIncludePath
      @param {String} path A single include path. Example: "comments.author"
      @private
    */
    _trackLoadedIncludePath: function _trackLoadedIncludePath(path) {
      var _path$split = path.split("."),
        _path$split2 = _toArray(_path$split),
        firstInclude = _path$split2[0],
        rest = _path$split2.slice(1);
      var relationship = Ember.String.camelize(firstInclude);
      if (this._hasNamedRelationship(relationship)) {
        this._loadedReferences[relationship] = true;
        if (rest.length) {
          this._getRelationshipModels(relationship).filter(function (model) {
            return model.trackLoadedIncludes;
          }).forEach(function (model) {
            return model.trackLoadedIncludes(rest.join('.'));
          });
        }
      }
    },
    /**
      This method can take an include string and see if the graph of objects
      in the store related to this model have all loaded each of the elements
      in that include string.
       @method _graphHasLoaded
      @param {String} includes A full include path. Example: "author,comments.author,tags"
      @return {Boolean} True if the includes have been loaded, false if not
      @private
    */
    _graphHasLoaded: function _graphHasLoaded(includes) {
      var _this3 = this;
      return includes.split(",").every(function (path) {
        return _this3._graphHasLoadedPath(path);
      });
    },
    /**
      Checks wether a single include path has been loaded.
       @method _graphHasLoadedPath
      @param {String} path A single include path. Example: "comments.author"
      @return {Boolean} True if the path has been loaded, false if not
      @private
    */
    _graphHasLoadedPath: function _graphHasLoadedPath(includePath) {
      var _includePath$split = includePath.split("."),
        _includePath$split2 = _toArray(_includePath$split),
        firstInclude = _includePath$split2[0],
        rest = _includePath$split2.slice(1);
      var relationship = Ember.String.camelize(firstInclude);
      var reference = this._getReference(relationship);
      var hasLoaded = reference && this._hasLoadedReference(relationship);
      if (rest.length === 0) {
        return hasLoaded;
      } else {
        var models = this._getRelationshipModels(relationship);
        var childrenHaveLoaded = models.every(function (model) {
          return model.trackLoadedIncludes && model._graphHasLoaded(rest.join("."));
        });
        return hasLoaded && childrenHaveLoaded;
      }
    },
    /**
      Checks if storefront has ever loaded this reference.
       @method _hasLoadedReference
      @param {String} name Reference or relationshipname name.
      @return {Boolean} True if storefront has loaded the reference.
      @private
    */
    _hasLoadedReference: function _hasLoadedReference(name) {
      return this._loadedReferences[name];
    },
    /**
      This method returns true if the provided includes string has been loaded and false if not.
       @method hasLoaded
      @param {String} includesString a JSON:API includes string representing the relationships to check
      @return {Boolean} true if the includes has been loaded, false if not
      @public
    */
    hasLoaded: function hasLoaded(includesString) {
      var modelName = this.constructor.modelName;
      return this.get('store').hasLoadedIncludesForRecord(modelName, this.get('id'), includesString) || this._graphHasLoaded(includesString);
    }
  });
  _exports.default = _default;
});