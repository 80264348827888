define("ember-concurrency/-buffer-policy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.enqueueTasksPolicy = _exports.dropQueuedTasksPolicy = _exports.dropButKeepLatestPolicy = _exports.cancelOngoingTasksPolicy = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var saturateActiveQueue = function saturateActiveQueue(scheduler) {
    while (scheduler.activeTaskInstances.length < scheduler.maxConcurrency) {
      var taskInstance = scheduler.queuedTaskInstances.shift();
      if (!taskInstance) {
        break;
      }
      scheduler.activeTaskInstances.push(taskInstance);
    }
  };
  function numPerformSlots(scheduler) {
    return scheduler.maxConcurrency - scheduler.queuedTaskInstances.length - scheduler.activeTaskInstances.length;
  }
  var enqueueTasksPolicy = {
    requiresUnboundedConcurrency: true,
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [a,b,c] [d,e,f]
      saturateActiveQueue(scheduler);
    },
    getNextPerformStatus: function getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'enqueue';
    }
  };
  _exports.enqueueTasksPolicy = enqueueTasksPolicy;
  var dropQueuedTasksPolicy = {
    cancelReason: "it belongs to a 'drop' Task that was already running",
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [a,b,c] []
      saturateActiveQueue(scheduler);
      scheduler.spliceTaskInstances(this.cancelReason, scheduler.queuedTaskInstances, 0, scheduler.queuedTaskInstances.length);
    },
    getNextPerformStatus: function getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'drop';
    }
  };
  _exports.dropQueuedTasksPolicy = dropQueuedTasksPolicy;
  var cancelOngoingTasksPolicy = {
    cancelReason: "it belongs to a 'restartable' Task that was .perform()ed again",
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [d,e,f] []
      var activeTaskInstances = scheduler.activeTaskInstances;
      var queuedTaskInstances = scheduler.queuedTaskInstances;
      activeTaskInstances.push.apply(activeTaskInstances, _toConsumableArray(queuedTaskInstances));
      queuedTaskInstances.length = 0;
      var numToShift = Math.max(0, activeTaskInstances.length - scheduler.maxConcurrency);
      scheduler.spliceTaskInstances(this.cancelReason, activeTaskInstances, 0, numToShift);
    },
    getNextPerformStatus: function getNextPerformStatus(scheduler) {
      return numPerformSlots(scheduler) > 0 ? 'succeed' : 'cancel_previous';
    }
  };
  _exports.cancelOngoingTasksPolicy = cancelOngoingTasksPolicy;
  var dropButKeepLatestPolicy = {
    cancelReason: "it belongs to a 'keepLatest' Task that was already running",
    schedule: function schedule(scheduler) {
      // [a,b,_] [c,d,e,f] becomes
      // [d,e,f] []
      saturateActiveQueue(scheduler);
      scheduler.spliceTaskInstances(this.cancelReason, scheduler.queuedTaskInstances, 0, scheduler.queuedTaskInstances.length - 1);
    }
  };
  _exports.dropButKeepLatestPolicy = dropButKeepLatestPolicy;
});