define("ember-cli-qrcode/system/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gexp = gexp;
  _exports.glog = glog;
  var EXP_TABLE = new Array(256);
  var LOG_TABLE = new Array(256);
  for (var i = 0; i < 8; i++) {
    EXP_TABLE[i] = 1 << i;
  }
  for (var _i = 8; _i < 256; _i++) {
    EXP_TABLE[_i] = EXP_TABLE[_i - 4] ^ EXP_TABLE[_i - 5] ^ EXP_TABLE[_i - 6] ^ EXP_TABLE[_i - 8];
  }
  for (var _i2 = 0; _i2 < 255; _i2++) {
    LOG_TABLE[EXP_TABLE[_i2]] = _i2;
  }
  function glog(n) {
    if (n < 1) {
      throw new Error("glog(" + n + ")");
    }
    return LOG_TABLE[n];
  }
  function gexp(n) {
    while (n < 0) {
      n += 255;
    }
    while (n >= 256) {
      n -= 255;
    }
    return EXP_TABLE[n];
  }
});