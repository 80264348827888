define("ember-leaflet/initializers/leaflet-assets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* global L */

  function initialize(container /*, application */) {
    if (typeof FastBoot === 'undefined' && typeof L !== 'undefined') {
      var config = container.resolveRegistration('config:environment');
      var prefix = '';
      if (!Ember.isNone(config.rootURL)) {
        prefix = config.rootURL;
      } else if (!Ember.isNone(config.baseURL)) {
        prefix = config.baseURL;
      }
      L.Icon.Default.imagePath = "".concat(prefix, "assets/images/");
    }
  }
  var _default = {
    name: 'leaflet-assets',
    initialize: initialize
  };
  _exports.default = _default;
});