define('ember-useragent/initializers/user-agent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  /**
   * Ember UserAgent initializer
   *
   * Supports auto injection of the userAgent service app-wide.
   * Generated by the ember-useragent addon.
   */
  function initialize() {
    var application = arguments[1] || arguments[0];

    application.inject('controller', 'userAgent', 'service:userAgent');
    application.inject('component', 'userAgent', 'service:userAgent');
    application.inject('route', 'userAgent', 'service:userAgent');
    application.inject('view', 'userAgent', 'service:userAgent');
  }

  exports.default = {
    name: 'user-agent',
    initialize: initialize
  };
});