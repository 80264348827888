define("ember-l10n/test-helpers", ["exports", "ember-l10n/services/l10n"], function (_exports, _l10n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _default(context) {
    var tHelper = Ember.Helper.helper(function (_ref, hash) {
      var _ref2 = _slicedToArray(_ref, 1),
        str = _ref2[0];
      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    var ptHelper = Ember.Helper.helper(function (_ref3, hash) {
      var _ref4 = _slicedToArray(_ref3, 1),
        str /* , ctxt*/ = _ref4[0];
      return (0, _l10n.strfmt)(str, Ember.assign({}, hash));
    });
    var nHelper = Ember.Helper.helper(function (_ref5, hash) {
      var _ref6 = _slicedToArray(_ref5, 3),
        strSingular = _ref6[0],
        strPlural = _ref6[1],
        count = _ref6[2];
      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count: count
      }, hash));
    });
    var pnHelper = Ember.Helper.helper(function (_ref7, hash) {
      var _ref8 = _slicedToArray(_ref7, 3),
        strSingular = _ref8[0],
        strPlural = _ref8[1],
        count /* , ctxt*/ = _ref8[2];
      return (0, _l10n.strfmt)(count !== 1 ? strPlural : strSingular, Ember.assign({
        count: count
      }, hash));
    });
    context.register('helper:t', tHelper);
    context.register('helper:n', nHelper);
    context.register('helper:pt', ptHelper);
    context.register('helper:pn', pnHelper);
    (false && !(false) && Ember.deprecate("ember-l10n/test-helpers has been deprecated.\n    You can use the helpers normally in your integration tests, without any further action.", false, {
      id: 'ember-l10n.test-helpers',
      until: '4.0.0'
    }));
  }
});