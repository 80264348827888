define("ember-notify/index", ["exports", "ember-notify/message"], function (_exports, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function aliasToShow(type) {
    return function (message, options) {
      return this.show(type, message, options);
    };
  }
  var Notify = Ember.Service.extend({
    info: aliasToShow('info'),
    success: aliasToShow('success'),
    warning: aliasToShow('warning'),
    alert: aliasToShow('alert'),
    error: aliasToShow('error'),
    init: function init() {
      this._super.apply(this, arguments);
      this.pending = [];
    },
    show: function show(type, text, options) {
      // If the text passed is `SafeString`, convert it
      if (Ember.String.isHTMLSafe(text)) {
        text = text.toString();
      }
      if (_typeof(text) === 'object') {
        options = text;
        text = null;
      }
      var message = _message.default.create(Ember.assign({
        text: text,
        type: type
      }, options));
      if (this.target) {
        this.target.show(message);
      } else {
        this.pending.push(message);
      }
      return message;
    },
    setTarget: function setTarget(target) {
      this.set('target', target);
      if (target) {
        this.pending.map(function (message) {
          return target.show(message);
        });
        this.pending = [];
      }
    }
  });
  var _default = Notify.reopenClass({
    property: function property() {
      return Ember.computed(function () {
        return Notify.create();
      });
    }
  });
  _exports.default = _default;
});