define("ember-cli-ifa/services/asset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    enabled: false,
    map: Ember.computed(function () {
      return {};
    }),
    prepend: '/',
    fullMap: Ember.computed('map', function () {
      var map = Ember.get(this, 'map');
      var ret = {};
      Object.keys(map).forEach(function (k) {
        var v = map[k];
        ret[k] = v;
        ret[v] = v;
      });
      return ret;
    }),
    resolve: function resolve(name) {
      var fullMap = Ember.get(this, 'fullMap') || {};
      var prepend = Ember.get(this, 'prepend');
      var enabled = Ember.get(this, 'enabled');
      var assetName = enabled ? fullMap[name] || name : name;
      return "".concat(prepend).concat(assetName);
    }
  });
  _exports.default = _default;
});