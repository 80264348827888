define("ember-notify/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMPTY_ARRAY = [];
  var _default = Ember.Object.extend({
    text: null,
    html: '',
    type: 'info',
    closeAfter: undefined,
    visible: undefined,
    classNames: EMPTY_ARRAY,
    // will be set to the component rendering this message
    container: undefined,
    close: function close() {
      if (this.container) {
        this.container.selfClose();
      }
    }
  });
  _exports.default = _default;
});