define("ember-macro-helpers/-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ARRAY_LENGTH = _exports.ARRAY_EACH = void 0;
  var ARRAY_EACH = '@each.';
  _exports.ARRAY_EACH = ARRAY_EACH;
  var ARRAY_LENGTH = '[]';
  _exports.ARRAY_LENGTH = ARRAY_LENGTH;
});