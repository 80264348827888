define("ember-table/components/ember-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I2saGsSi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[35,0],[35,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\\n  \"],[1,[30,[36,2],null,[[\"columnMeta\"],[[35,1]]]]],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"columnMeta\"],[[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"columnValue\",\"columnMeta\",\"ember-th/sort-indicator\",\"ember-th/resize-handle\",\"if\"]}",
    "meta": {
      "moduleName": "ember-table/components/ember-th/template.hbs"
    }
  });
  _exports.default = _default;
});