define("ember-cli-ifa/helpers/asset-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    assetMap: Ember.inject.service(),
    compute: function compute(params) {
      var file = params[0] || "";
      if (!file) {
        return;
      }
      return Ember.get(this, 'assetMap').resolve(file);
    }
  });
  _exports.default = _default;
});