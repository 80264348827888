define("semantic-ui-ember/components/ui-nag", ["exports", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-nag"], function (_exports, _base, _uiNag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_base.default, {
    layout: _uiNag.default,
    module: 'nag',
    classNames: ['ui', 'nag']
  });
  _exports.default = _default;
});