define('ember-fullcalendar/components/full-calendar', ['exports', 'ember-fullcalendar/templates/components/full-calendar', 'ember-invoke-action'], function (exports, _fullCalendar, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assign = Ember.assign,
      observer = Ember.observer,
      computed = Ember.computed,
      getOwner = Ember.getOwner;
  exports.default = Ember.Component.extend(_emberInvokeAction.InvokeActionMixin, {

    /////////////////////////////////////
    // PROPERTIES
    /////////////////////////////////////

    layout: _fullCalendar.default,
    classNames: ['full-calendar'],

    /////////////////////////////////////
    // FULLCALENDAR OPTIONS
    /////////////////////////////////////

    // scheduler defaults to non-commercial license
    schedulerLicenseKey: computed(function () {

      // load the consuming app's config
      var applicationConfig = getOwner(this).resolveRegistration('config:environment');
      var defaultSchedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';

      if (applicationConfig && applicationConfig.emberFullCalendar && applicationConfig.emberFullCalendar.schedulerLicenseKey) {
        return applicationConfig.emberFullCalendar.schedulerLicenseKey;
      }

      return defaultSchedulerLicenseKey;
    }),

    fullCalendarOptions: [
    // general display
    'header', 'footer', 'customButtons', 'buttonIcons', 'themeSystem', 'theme', 'themeButtonIcons', 'bootstrapGlyphicons', 'firstDay', 'isRTL', 'weekends', 'hiddenDays', 'fixedWeekCount', 'weekNumbers', 'weekNumberCalculation', 'businessHours', 'height', 'contentHeight', 'aspectRatio', 'handleWindowResize', 'eventLimit', 'weekNumbersWithinDays', 'showNonCurrentDates',

    // clicking & hovering
    'navLinks',

    // timezone
    'timezone', 'now',

    // views
    'views',

    // agenda options
    'allDaySlot', 'allDayText', 'slotDuration', 'slotLabelFormat', 'slotLabelInterval', 'snapDuration', 'scrollTime', 'minTime', 'maxTime', 'slotEventOverlap', 'agendaEventMinHeight',

    // list options
    'listDayFormat', 'listDayAltFormat', 'noEventsMessage',

    // current date
    'nowIndicator', 'visibleRange', 'validRange', 'dateIncrement', 'dateAlignment', 'duration', 'dayCount',

    // text/time customization
    'locale', 'timeFormat', 'columnFormat', 'titleFormat', 'buttonText', 'monthNames', 'monthNamesShort', 'dayNames', 'dayNamesShort', 'weekNumberTitle', 'displayEventTime', 'displayEventEnd', 'eventLimitText', 'dayPopoverFormat',

    // selection
    'selectable', 'selectHelper', 'unselectAuto', 'unselectCancel', 'selectOverlap', 'selectConstraint', 'selectAllow', 'selectMinDistance', 'selectLongPressDelay',

    // event data
    'events', 'eventSources', 'allDayDefault', 'startParam', 'endParam', 'timezoneParam', 'lazyFetching', 'defaultTimedEventDuration', 'defaultAllDayEventDuration', 'forceEventDuration',

    // event rendering
    'eventColor', 'eventBackgroundColor', 'eventBorderColor', 'eventTextColor', 'nextDayThreshold', 'eventOrder', 'eventRenderWait',

    // event dragging & resizing
    'editable', 'eventStartEditable', 'eventDurationEditable', 'dragRevertDuration', 'dragOpacity', 'dragScroll', 'eventOverlap', 'eventConstraint', 'eventAllow', 'longPressDelay', 'eventLongPressDelay',

    // dropping external elements
    'droppable', 'dropAccept',

    // timeline view
    'resourceAreaWidth', 'resourceLabelText', 'resourceColumns', 'slotWidth',

    // resource data
    'resources', 'eventResourceField', 'refetchResourcesOnNavigate',

    // resource rendering
    'resourceOrder', 'resourceGroupField', 'resourceGroupText', 'resourcesInitiallyExpanded', 'filterResourcesWithEvents',

    // vertical resource view
    'groupByResource', 'groupByDateAndResource'],

    fullCalendarEvents: [
    // general display
    'viewRender', 'viewDestroy', 'dayRender', 'windowResize',

    // clicking and hovering
    'dayClick', 'eventClick', 'eventMouseover', 'eventMouseout', 'navLinkDayClick', 'navLinkWeekClick',

    // selection
    'select', 'unselect',

    // event data
    'eventDataTransform', 'loading',

    // event rendering
    'eventRender', 'eventAfterRender', 'eventAfterAllRender', 'eventDestroy', 'eventLimitClick',

    // event dragging & resizing
    'eventDragStart', 'eventDragStop', 'eventDrop', 'eventResizeStart', 'eventResizeStop', 'eventResize',

    // dropping external events
    'drop', 'eventReceive',

    // resource rendering
    'resourceText', 'resourceRender'],

    /////////////////////////////////////
    // SETUP/TEARDOWN
    /////////////////////////////////////

    didInsertElement: function didInsertElement() {

      var options = assign(this.get('options'), this.get('hooks'));

      // Temporary patch for `eventDataTransform` method throwing error
      options.eventDataTransform = this.get('eventDataTransform');

      // add the license key for the scheduler
      options.schedulerLicenseKey = this.get('schedulerLicenseKey');

      this.$().fullCalendar(options);
    },
    willDestroyElement: function willDestroyElement() {
      this.$().fullCalendar('destroy');
    },


    /////////////////////////////////////
    // COMPUTED PROPERTIES
    /////////////////////////////////////

    /**
     * Returns all of the valid Fullcalendar options that
     * were passed into the component.
     */
    options: computed(function () {
      var _this = this;

      var fullCalendarOptions = this.get('fullCalendarOptions');
      var options = {};

      // Apply FullCalendar options based on property name
      fullCalendarOptions.forEach(function (optionName) {
        if (_this.get(optionName) !== undefined) {
          options[optionName] = _this.get(optionName);
        }
      });

      // Handle overriden properties
      if (this.get('viewName') !== undefined) {
        options['defaultView'] = this.get('viewName');
      }

      if (this.get('date') !== undefined) {
        options['defaultDate'] = this.get('date');
      }

      return options;
    }),

    /**
     * Returns all of the valid Fullcalendar callback event
     * names that were passed into the component.
     */
    usedEvents: computed('fullCalendarEvents', function () {
      var _this2 = this;

      return this.get('fullCalendarEvents').filter(function (eventName) {
        var methodName = '_' + eventName;
        return _this2.get(methodName) !== undefined || _this2.get(eventName) !== undefined;
      });
    }),

    /**
     * Returns an object that contains a function for each action passed
     * into the component. This object is passed into Fullcalendar.
     */
    hooks: computed(function () {
      var _this3 = this;

      var actions = {};

      this.get('usedEvents').forEach(function (eventName) {

        // create an event handler that runs the function inside an event loop.
        actions[eventName] = function () {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          Ember.run.schedule('actions', _this3, function () {
            _this3.invokeAction.apply(_this3, [eventName].concat(args, [_this3.$()]));
          });
        };
      });

      return actions;
    }),

    /////////////////////////////////////
    // OBSERVERS
    /////////////////////////////////////

    /**
     * Observe the events array for any changes and
     * re-render if changes are detected
     */
    observeEvents: observer('events.[]', function () {
      var fc = this.$();
      var events = this.get('events');

      fc.fullCalendar('removeEvents');

      if (events) {
        fc.fullCalendar('addEventSource', this.get('events'));
      }
    }),

    /**
     * Observe the eventSources array for any changes and
     * re-render if changes are detected
     */
    observeEventSources: observer('eventSources.[]', function () {
      var fc = this.$();

      fc.fullCalendar('removeEventSources');

      this.get('eventSources').forEach(function (source) {
        if (source) {
          fc.fullCalendar('addEventSource', source);
        }
      });
    }),

    /**
     * Observes the resources array and refreshes the resource view
     * if any changes are detected
     * @type {[type]}
     */
    observeResources: observer('resources.[]', function () {
      var fc = this.$();
      fc.fullCalendar('refetchResources');
    }),

    /**
     * Observes the 'viewName' property allowing FullCalendar view to be
     * changed from outside of the component.
     */
    viewNameDidChange: Ember.observer('viewName', function () {
      var viewName = this.get('viewName');
      var viewRange = this.get('viewRange');

      this.$().fullCalendar('changeView', viewName, viewRange);

      // Call action if it exists
      if (this.get('onViewChange')) {
        this.get('onViewChange')(viewName, viewRange);
      }
    }),

    /**
     * Observes `date` property allowing date to be changed from outside
     * of the component.
     */
    dateDidChange: Ember.observer('date', function () {
      var date = this.get('date');
      this.$().fullCalendar('gotoDate', date);
    })

  });
});