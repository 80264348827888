define("ember-cli-ifa/initializers/asset-map", ["exports", "ember-cli-ifa/services/asset-map", "ember-cli-ifa/utils/get-asset-map-data"], function (_exports, _assetMap, _getAssetMapData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    var assetMapFile = (0, _getAssetMapData.default)();
    if (!assetMapFile) {
      app.register('service:asset-map', _assetMap.default);
      return;
    }
    if (Ember.typeOf(assetMapFile) === 'object' && assetMapFile.assets) {
      _assetMap.default.reopen({
        map: assetMapFile.assets,
        prepend: assetMapFile.prepend,
        enabled: true
      });
      app.register('service:asset-map', _assetMap.default);
    } else {
      app.deferReadiness();
      var promise = fetch(assetMapFile).then(function (response) {
        return response.json();
      });
      promise.then(function () {
        var map = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        _assetMap.default.reopen({
          map: map.assets,
          prepend: map.prepend,
          enabled: true
        });
      }).then(function () {
        app.register('service:asset-map', _assetMap.default);
      }).catch(function (err) {
        console.error('Failed to register service:asset-map', err);
      }).finally(function () {
        app.advanceReadiness();
      });
    }
  }
  var _default = {
    name: 'asset-map',
    initialize: initialize
  };
  _exports.default = _default;
});