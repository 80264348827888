define("ember-cli-stripe/services/stripe", ["exports", "ember-invoke-action", "ember-cli-stripe/utils/configuration-options"], function (_exports, _emberInvokeAction, _configurationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = Ember.Service.extend({
    /*
     * Registers a component as the current target of this service.
     * When the first {{stripe-checkout}} component is registered we load
     * the StripeCheckout js lib.
     *
     * @public
     */
    registerComponent: function registerComponent(component) {
      this._alive[Ember.guidFor(component)] = {
        component: component
      };

      // Load StripeCheckout js lib
      this._loadStripeJs();
    },
    /**
     * Unregisters a component.
     *
     * @public
     */
    unregisterComponent: function unregisterComponent(component) {
      delete this._alive[Ember.guidFor(component)];
    },
    /*
     * Open opens the StripeCheckout payment modal.
     *
     * @public
     */
    open: function open(component) {
      var _this = this;
      this._stripeScriptPromise.then(function () {
        var config = _this._stripeConfig(component);
        var stripeHandler = _this._stripeHandler(component);
        stripeHandler.open(config);
      });
    },
    /*
     * Close closes the StripeCheckout payment modal.
     * @public
     */
    close: function close(component) {
      var stripeHandler = this._stripeHandler(component);
      stripeHandler.close();
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._alive = {};
      this._scriptLoaded = false;
      this._scriptLoading = false;
    },
    /**
     * Looks for any Stripe config options on the component.
     */
    _componentStripeConfig: function _componentStripeConfig(component) {
      var componentOptions = component.getProperties(_configurationOptions.default);
      return this._cleanupOptions(componentOptions);
    },
    /**
     * Stripe config options with env configs merged with the ones provided through
     * the component.
     */
    _stripeConfig: function _stripeConfig(component) {
      var stripeConfig = Ember.getWithDefault(this, 'stripeConfig', {});
      var options = {};
      if (Ember.assign) {
        Ember.assign(options, stripeConfig, this._componentStripeConfig(component));
      } else {
        // Support for Ember <= 2.4 (when assign was introduced)
        Ember.merge(options, stripeConfig);
        Ember.merge(options, this._componentStripeConfig(component));
      }
      return this._cleanupOptions(options);
    },
    _stripeHandler: function _stripeHandler(component) {
      var componentGuid = Ember.guidFor(component);
      if ('handler' in this._alive[componentGuid]) {
        return this._alive[componentGuid]['handler'];
      }
      var stripeConfig = this._stripeConfig(component);
      if (!('key' in stripeConfig)) {
        throw new Error('[ember-cli-stripe] Missing required `key` param');
      }
      var handler = StripeCheckout.configure({
        key: stripeConfig.key,
        token: function token() {
          _emberInvokeAction.invokeAction.apply(void 0, [component, 'onToken'].concat(Array.prototype.slice.call(arguments)));

          // Add deprecation for previous `action` callback
          if (!Ember.isBlank(component.attrs.action)) {
            (false && !(false) && Ember.deprecate('Using `action` callback is deprecated and will be removed in future versions. Please use `onToken` with a closure action instead', false, {
              id: 'ember-cli-stripe.action-callback',
              until: '1.1.0'
            }));
            _emberInvokeAction.invokeAction.apply(void 0, [component, 'action'].concat(Array.prototype.slice.call(arguments)));
          }
        },
        opened: function opened() {
          (0, _emberInvokeAction.invokeAction)(component, 'onOpened');
        },
        closed: function closed() {
          (0, _emberInvokeAction.invokeAction)(component, 'onClosed');
        }
      });
      this._alive[componentGuid]['handler'] = handler;
      return handler;
    },
    _loadStripeJs: function _loadStripeJs() {
      var _arguments = arguments,
        _this2 = this;
      if (this._scriptLoaded || this._scriptLoading) {
        return this._stripeScriptPromise;
      }
      this._scriptLoading = true;
      var script = document.createElement('script');
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.async = true;
      script.type = 'text/javascript';
      this._stripeScriptPromise = new Ember.RSVP.Promise(function (resolve, reject) {
        script.onload = function () {
          _this2._scriptLoaded = true;
          resolve();
          _this2.onScriptLoaded();
        };
        script.onerror = function () {
          _this2._invokeAction.apply(_this2, ['onStripeLoadError'].concat(_toConsumableArray(_arguments)));
          reject();
        };
        document.body.appendChild(script);
      });
      return this._stripeScriptPromise;
    },
    onScriptLoaded: function onScriptLoaded() {},
    onStripeLoadError: function onStripeLoadError() {},
    _cleanupOptions: function _cleanupOptions(options) {
      var cleanedOptions = {};
      for (var key in options) {
        if (Ember.typeOf(options[key]) !== 'undefined') {
          cleanedOptions[key] = options[key];
        }
      }
      return cleanedOptions;
    }
  });
  _exports.default = _default;
});