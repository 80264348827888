define("ember-data-has-many-query/property-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stickyPropertyName = _exports.queryParamPropertyName = _exports.queryIdPropertyName = _exports.lastWasErrorPropertyName = _exports.ajaxOptionsPropertyName = void 0;
  var queryParamPropertyName = function queryParamPropertyName(key) {
    return '__' + key + 'QueryParams';
  };
  _exports.queryParamPropertyName = queryParamPropertyName;
  var queryIdPropertyName = function queryIdPropertyName(key) {
    return '__' + key + 'QueryId';
  };
  _exports.queryIdPropertyName = queryIdPropertyName;
  var lastWasErrorPropertyName = function lastWasErrorPropertyName(key) {
    return '__' + key + 'LastWasError';
  };
  _exports.lastWasErrorPropertyName = lastWasErrorPropertyName;
  var ajaxOptionsPropertyName = function ajaxOptionsPropertyName(key) {
    return '__' + key + 'AjaxOptions';
  };
  _exports.ajaxOptionsPropertyName = ajaxOptionsPropertyName;
  var stickyPropertyName = function stickyPropertyName(key) {
    return '__' + key + 'BelongsToSticky';
  };
  _exports.stickyPropertyName = stickyPropertyName;
});