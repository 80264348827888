define("ember-element-helper/helpers/-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function UNINITIALIZED() {}
  var DynamicElement = Ember.Component.extend();
  var DynamicElementAlt = Ember.Component.extend();
  var _default = Ember.Helper.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.tagName = UNINITIALIZED;
      this.componentClass = null;
    },
    compute: function compute(params, hash) {
      (false && !(params.length === 1) && Ember.assert('The `element` helper takes a single positional argument', params.length === 1));
      (false && !(Object.keys(hash).length === 0) && Ember.assert('The `element` helper does not take any named arguments', Object.keys(hash).length === 0));
      var tagName = params[0];
      if (tagName !== this.tagName) {
        this.tagName = tagName;
        if (typeof tagName === 'string') {
          // return a different component name to force a teardown
          if (this.componentClass === DynamicElement) {
            this.componentClass = DynamicElementAlt;
          } else {
            this.componentClass = DynamicElement;
          }
        } else {
          this.componentClass = null;
          Ember.runInDebug(function () {
            var message = 'The argument passed to the `element` helper must be a string';
            try {
              message += " (you passed `".concat(tagName, "`)");
            } catch (e) {
              // ignore
            }
            (false && !(tagName === undefined || tagName === null) && Ember.assert(message, tagName === undefined || tagName === null));
          });
        }
      }
      return this.componentClass;
    }
  });
  _exports.default = _default;
});