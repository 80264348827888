define("ember-table/components/-private/base-table-cell", ["exports", "ember-table/-private/utils/observer"], function (_exports, _observer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Provided by subclasses
    columnMeta: null,
    columnValue: null,
    classNameBindings: ['isFirstColumn', 'isFixedLeft', 'isFixedRight', 'textAlign'],
    isFirstColumn: Ember.computed.equal('columnMeta.index', 0),
    isFixedLeft: Ember.computed.equal('columnMeta.isFixed', 'left'),
    isFixedRight: Ember.computed.equal('columnMeta.isFixed', 'right'),
    /**
     Indicates the text alignment of this cell
    */
    textAlign: Ember.computed('columnValue.textAlign', function () {
      var textAlign = this.get('columnValue.textAlign');
      if (['left', 'center', 'right'].includes(textAlign)) {
        return "ember-table__text-align-".concat(textAlign);
      }
      return null;
    }),
    // eslint-disable-next-line
    scheduleUpdateStyles: (0, _observer.observer)('columnMeta.{width,offsetLeft,offsetRight}', 'isFixedLeft', 'isFixedRight', function () {
      Ember.run.scheduleOnce('actions', this, 'updateStyles');
    }),
    updateStyles: function updateStyles() {
      if (typeof FastBoot === 'undefined' && this.element) {
        var width = "".concat(this.get('columnMeta.width'), "px");
        this.element.style.width = width;
        this.element.style.minWidth = width;
        this.element.style.maxWidth = width;
        if (this.get('isFixedLeft')) {
          this.element.style.left = "".concat(Math.round(this.get('columnMeta.offsetLeft')), "px");
        } else if (this.get('isFixedRight')) {
          this.element.style.right = "".concat(Math.round(this.get('columnMeta.offsetRight')), "px");
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateStyles();
    }
  });
  _exports.default = _default;
});