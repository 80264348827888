define("ember-simple-auth-token/mixins/token-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
    Adapter Mixin that works with token-based authentication like JWT.
  
    @class TokenAdapter
    @module ember-simple-auth-token/mixins/token-adapter
    @extends Ember.Mixin
  */
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    /**
      @method init
    */
    init: function init() {
      this._super.apply(this, arguments);
      var owner = Ember.getOwner(this);
      var environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      var config = environment['ember-simple-auth-token'] || {};
      this.tokenPropertyName = config.tokenPropertyName || 'token';
      this.authorizationHeaderName = config.authorizationHeaderName || 'Authorization';
      this.authorizationPrefix = config.authorizationPrefix === '' ? '' : config.authorizationPrefix || 'Bearer ';
    },
    /*
      Adds the `token` property from the session to the `authorizationHeaderName`.
    */
    headers: Ember.computed('session.data.authenticated', function () {
      var data = this.get('session.data.authenticated');
      var token = Ember.get(data, this.get('tokenPropertyName'));
      var prefix = this.get('authorizationPrefix');
      var header = this.get('authorizationHeaderName');
      if (this.get('session.isAuthenticated') && !Ember.isEmpty(token)) {
        return _defineProperty({}, header, "".concat(prefix).concat(token));
      } else {
        return {};
      }
    }),
    /**
      Handles response from server.
       @method authorize
      @param {Number} status
    */
    handleResponse: function handleResponse(status) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});