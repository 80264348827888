define("semantic-ui-ember/components/ui-search", ["exports", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-search"], function (_exports, _base, _uiSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_base.default, {
    layout: _uiSearch.default,
    module: 'search',
    classNames: ['ui', 'search'],
    didInitSemantic: function didInitSemantic() {
      this._super.apply(this, arguments);
      this.get('_bindableAttrs').addObject('source');
    },
    execute: function execute() {
      var cmd = arguments.length <= 0 ? undefined : arguments[0];
      var attrValue = arguments.length <= 1 ? undefined : arguments[1];
      if (cmd === 'set source') {
        var module = this.getSemanticModule();
        if (module) {
          return module.apply(this.getSemanticScope(), [{
            source: attrValue
          }]);
        }
      }
      return this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});