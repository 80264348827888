define("ember-table/components/ember-th/sort-indicator/component", ["exports", "ember-table/components/ember-th/sort-indicator/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    The table header cell sort indicator component. This component renders the state of the sort on the column (ascending/descending/none).
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{columns}} as |h|>
        <h.row as |r|>
          <r.cell as |columnValue columnMeta|>
            {{columnValue.name}}
  
            <EmberTh::SortIndicator @columnMeta={{columnMeta}} />
          </r.cell>
        </h.row>
      </t.head>
  
      <t.body @rows={{rows}} />
    </EmberTable>
    ```
    @yield {object} columnMeta - The meta object associated with this column
    @class {{ember-th/sort-indicator}}
  */
  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    /**
      The API object passed in by the table header cell
      @argument columnMeta
      @required
      @type object
    */
    columnMeta: null,
    isSortable: Ember.computed.readOnly('columnMeta.isSortable'),
    isSorted: Ember.computed.readOnly('columnMeta.isSorted'),
    isSortedAsc: Ember.computed.readOnly('columnMeta.isSortedAsc'),
    isMultiSorted: Ember.computed.readOnly('columnMeta.isMultiSorted'),
    sortIndex: Ember.computed.readOnly('columnMeta.sortIndex')
  });
  _exports.default = _default;
});